export const Content = {
  hero: {
    subtitle: 'We help the world’s leading companies understand their users in real time',
    src: '/images/v2/about/about-hero.png',
    title: 'Welcome to **unitQ**',
  },
  portraits: {
    board: {
      members: [
        {
          src: '/images/v2/about/darian.png',
          href: 'https://www.gradient.com/profile/darian-shirazi/',
          name: 'Darian Shirazi',
          role: 'Board Member',
        },
        {
          src: '/images/v2/about/andrew.png',
          href: 'https://www.accel.com/people/andrew-braccia',
          name: 'Andrew Braccia',
          role: 'Board Member',
        },
        {
          src: '/images/v2/about/scott.png',
          href: 'https://en.wikipedia.org/wiki/Scott_Weiss',
          name: 'Scott Weiss',
          role: 'Board Member',
        },
      ],
      observers: [
        {
          src: '/images/v2/about/carl.png',
          href: 'https://creandum.com/team/carl-fritjofsson/',
          name: 'Carl Fritjofsson',
          role: 'Board Observer',
        },
        {
          src: '/images/v2/about/ben.png',
          href: 'https://www.accel.com/people/ben-fletcher',
          name: 'Ben Fletcher',
          role: 'Board Observer',
        },
      ],
      advisors: [
        { src: '/images/v2/about/denise.jpg', href: 'https://www.linkedin.com/in/denisepersson/', name: 'Denise Persson', role: 'Advisor' },
        { src: '/images/v2/about/steve.jpg', href: 'https://www.linkedin.com/in/stevemalouf/', name: 'Steve Malouf', role: 'Advisor' },
      ],
    },
    founders: [
      {
        src: '/images/v2/about/christian.png',
        href: 'https://www.linkedin.com/in/cwiklund/',
        name: 'Christian Wiklund',
        role: 'Co-founder & CEO',
      },
      {
        src:
          '/images/v2/about/niklas.png',
        href: 'https://www.linkedin.com/in/nlindstrom/',
        name: 'Niklas Lindstrom',
        role: 'Co-founder & CTO',
      },
    ],
    title: 'Meet our **leadership team**',
  },
  social: {
    linkedIn: {
      label: 'LinkedIn',
      mobileUrl: 'https://www.linkedin.com/mwlite/company/unitq',
      url: 'https://www.linkedin.com/company/unitq/mycompany/',
    },
    twitter: { label: 'Twitter', url: 'https://www.twitter.com/unitQsoftware' },
  },
};
