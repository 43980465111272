import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Nav, Image } from 'react-bootstrap';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { UqContainer, UqHeroV2, UqFigure, UqHeroStacked, UqLayoutV2, UqPage, UqTextHighlight, UqPortrait, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { figureRowShape } from '../models';
import { Content } from '../content/v2/about';

import Confetti from '@uq-assets/confetti-2.svg';

import * as styles from './about-us.module.scss';

UqAboutUsPage.propTypes = {
  data: PropTypes.shape({
    aboutFigure: figureRowShape,
    joinUsFigure: figureRowShape,
  }),
}

export default function UqAboutUsPage(props) {
  const { aboutFigure, joinUsFigure } = props.data;
  const { founders, board } = Content.portraits;

  const breakpoint = useBreakpoint();

  return (
    <UqPage
      config={{
        seo: {
          description:
            'unitQ is equipping companies to take a data driven approach to product quality. ' +
            'Learn more about our company and team.',
          title: 'The Leading Product Quality Company',
        },
      }}
    >
      <UqLayoutV2 theme="light">
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainer overflowY>
            <UqHeroStacked
              animations={{ image: 'fade' }}
              classes={{ root: styles.heroContainer }}
              title={Content.hero.title}
              subtitle={Content.hero.subtitle}
              image={(
                <Image
                  className={styles.heroImage}
                  src={Content.hero.src}
                />
              )}
            />
          </UqContainer>
        </UqHeroV2>

        <UqContainer>
          <UqFigure reverse={!aboutFigure.textAlign}>
            <UqFigure.Content>
              <UqFigure.Title>{aboutFigure.title}</UqFigure.Title>
              <span>{aboutFigure.description.description}</span>

              <br />
              <br />
              <div className={classNames('body-3', styles.social)}>
                Follow us on&nbsp;
                <Nav.Link
                  className={styles.link}
                  href={!breakpoint.xs
                    ? Content.social.linkedIn.url
                    : Content.social.linkedIn.mobileUrl}
                >
                  {Content.social.linkedIn.label}
                </Nav.Link>
                &nbsp;and&nbsp;
                <Nav.Link
                  className={styles.link}
                  href={Content.social.twitter.url}
                >
                  {Content.social.twitter.label}
                </Nav.Link>
              </div>
            </UqFigure.Content>

            <UqFigure.Image src={aboutFigure.image.url} />
          </UqFigure>
        </UqContainer>
        <UqContainer
          fullWidth
          background="dark"
          logoProps={{ anchor: 'bottomRight' }}
        >
          <UqContainer>
            <section className={styles.portraitContainer}>
              <h2 className={styles.portraitBlockTitle}>
                <UqTextHighlight>
                  {Content.portraits.title}
                </UqTextHighlight>
              </h2>
              <section className={styles.portraitRow}>
                <h3 className={styles.rowTitle}>
                  unitQ Founders
                </h3>
                <div className={styles.portraits}>
                  {founders.map(renderPortrait)}
                </div>
              </section>
              <section className={styles.portraitRow}>
                <h3 className={styles.rowTitle}>
                  Board of Directors
                </h3>
                <div className={styles.portraits}>
                  {board.members.map(renderPortrait)}
                </div>
                <div className={styles.portraits}>
                  {board.observers.map(renderPortrait)}
                </div>
              </section>
              <section className={styles.portraitRow}>
                <h3 className={styles.rowTitle}>
                  Advisors
                </h3>
                <div className={styles.portraits}>
                  {board.advisors.map(renderPortrait)}
                </div>
              </section>
            </section>
          </UqContainer>
        </UqContainer>
        <UqContainer
          className={styles.joinUsContainer}
          overflowX
        >
          <UqContainer>
            <Confetti className={styles.confetti} />
            <UqFigure>
              <UqFigure.Content>
                <UqFigure.Title>{joinUsFigure.title}</UqFigure.Title>

                <span>{joinUsFigure.description.description}</span>

                <UqFigure.Actions actions={joinUsFigure.actions} />
              </UqFigure.Content>

              <UqFigure.Image src={joinUsFigure.image.url} />
            </UqFigure>
          </UqContainer>
        </UqContainer>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );

  function renderPortrait(config, index) {
    return (
      <UqPortrait
        key={index}
        href={config.href}
        name={config.name}
        role={config.role}
        src={config.src}
      />
    );
  }
}

export const pageQuery = graphql`
  query {
    aboutFigure: contentfulFigureRow(pageId: {eq: "about"}) {
      actions {
        href
        label
        type
      }
      description {
        description
      }
      image {
        url
      }
      order
      textAlign
      title
    }
    joinUsFigure: contentfulFigureRow(pageId: {eq: "*-join-us"}) {
      actions {
        href
        label
        type
      }
      description {
        description
      }
      image {
        url
      }
      order
      textAlign
      title
    }
  }
`;
